.ql-container{
    height: 50vh !important;
}

.ql-editor {
    font-size: 16px !important;
    line-height: 1.8 !important;
}
 
.test_button{
    display: inline-block !important;
    background: black !important;
    color: white !important;
    border-radius: 16px !important;
    font-size: 0.74em !important;
    text-transform: inherit !important;
    margin: 2.5% !important;
    font-weight: 420 !important;
    letter-spacing: 0.09em !important;
    opacity: 0.8;
    width: 25%;
}