.resultsPage__banner{
  /* height: 5vh !important; */
  padding: 3%;
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: "center";
  color: rgb(52, 52, 52);
}

.resultsPage__body{
  /* padding-top: 11vh; */
  padding-left: 9vw;
  padding-right: 9vw;
  /* height: 60vh; */
  height: 55vh;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  /* padding-bottom: 60vh; */
}

.resultsPage__header{
  position: fixed;;
  top: 0 ;;
  left: 0 ;;
  right: 0;;
  height: 12vh;
  background-color: white ;
  z-index: 1000 ;
  padding-left: 3vw ;
  padding-top: 1vh ;
  padding-bottom: 1vh ;
  /* padding: ; */
  /* padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%; */
}

@media only screen and (min-width: 1000px) {
  /* .resultsPage__body{
    padding-top: 12vh !important;
    padding-left: 3vw;
    padding-right: 3vw;
    height: 50vh;
    overflow-y: auto;
    position: relative;
    z-index: 1;
  } */

  .resultsPage {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .resultsPage__body{
    /* padding-top: 27vh !important; */
    /* padding-left: 13vw !important;
    padding-right: 13vw !important; */
    height: 50vh !important;
    overflow-y: auto !important;
    position: relative !important;
    z-index: 1 !important;
  }

  .resultsPage__banner{
    padding-bottom: 0% !important;
  }

  .resultsPage__footer{
    padding: 0%;
  }

  .resultsPage__header{
    padding: 1% !important;
    padding-bottom: 1vh !important;
}
}

.resultsPage__header__logo{
    object-fit: contain;
    height: 50px;
    margin-right: 50px;
}
/* 
.resultsPage__header{
    padding-top: 3%;
    padding-left: 3.5%;
} */



/* @keyframes slide {
  0% {
    transform: translateX(0); 
  }
  100% {
    transform: translateX(-50%); 
  }
} */


.resultsPage__header{
  position: sticky !important;;
  top: 0 !important;;
  left: 0 !important;;
  right: 0 !important;;
  /* height: 60px; */
  height: 7vh;
  /* height: 12vh !important; */
  background-color: white !important;
  z-index: 1000 !important;
  padding-left: 3vw !important;
  padding-top: 1vh !important;
  /* padding-bottom: 2vh !important; */
  /* padding: ; */
  /* padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%; */
}

/* .resultsPage__body{
  padding-top: 20vh;
  padding-left: 3vw;
  padding-right: 3vw;
  height: 50vh;
  overflow-y: auto;
  position: relative;
  z-index: 1;
} */

/* .resultsPage__body{ */
    /* display: flex; */
    /* height: 35vh; */
    /* height: 40vh; */
    /* max-height: 40vh; */
    
    
    /* max-height: 20.8rem;
    overflow-y: auto;
    padding-left: 3.3%;
    padding-right: 3.3%; */


    /* animation: slide 10s linear infinite; 
    scrollbar-width: thin; 
    scrollbar-color: #888 #eee; */
    /* padding: 10px; */
    /* margin-left: 3%;
    margin-right: 3%; */
    /* height: 45vh; */
/* } */

.resultsPage__header__prompt__entry {
    width: 100vw;
}

/* .resultsPage__header__prompt__entry > .search > .search__input {
    margin-top: 0;
    width: unset;
    margin: unset;
    max-width: unset;
} */

.search__input{
    /* width: 95%; */
    margin: unset;
    max-width: unset;
}

.resultsSearch{
  margin-left: 10px !important;
  margin-top: 0 !important;
  width: 100% !important;
  margin: unset !important;
  max-width: unset !important;
}
/* .resultsPage__results{
    margin-top: 30px;
    margin-left: 240px;
    margin-bottom: 100px;
    max-width: 650px;
} */


/* .resultsPage__body {
    display: flex;
    width: 100%;
  } */
  
  .resultsPage__ai__response, .resultsPage__ai__response__spinner {
    font-family: system-ui;
  }
  
  
 .resultsPage__ai__title {
    font-weight: 500;
    color: blue;
    margin-bottom: 3%;
  }

  .resultsPage__result__title{
    text-decoration: none;
    color: black
  };

 .resultsPage__ai__title_h5 {
    color: black;
    font-size: revert;
    font-weight: 100;
    margin-top: 1%;
 }
  .resultsPage__references_header{
    font-weight: 500;
    color: blue;
    margin-bottom: 3%;
  }

  .resultsPage__results {
    flex-basis: 35%;
    flex-shrink: 0;
    margin: 3%;
    flex-shrink: 0;
    background-color: ghostwhite;
    border-radius: 10px;
    padding: 2.5%;
    font-size: large;
    font-family: system-ui;
    margin-top: 3%;
}
  

  .resultsPage__result {
    margin-bottom: 5%;
  }
  
  .resultsPage__result__snippet{
    margin-top: 2%;
  }

  .bingResults__container{
    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 5%;
    margin-left: 5%;
  }

  .bingResults__container__searchResult{
    margin: 3%;
    margin-top: 5%;
  }

  /* .resultsPage__result {
    padding: 1%;
  } */
  /* Optional: Add styles for responsiveness */
  @media screen and (max-width: 768px) {
    /* .resultsPage__body {
      flex-wrap: wrap;
    }
   */
    /* .resultsPage__ai__response,
    .resultsPage__results {
      flex-basis: 100%;
    } */
  }
  
  .generatedContent{
    font-family: "Roboto,Noto Sans,Noto Sans JP,Noto Sans KR,Noto Naskh Arabic,Noto Sans Thai,Noto Sans Hebrew,Noto Sans Bengali,sans-serif";
    line-height: 28px;
    font-size: 19px;
    font-weight: 500;
  }

  .ai__display__assistant > button {
    /* background-color: black; */
    color: black;
    /* opacity: 0.8; */
    /* border: 1px solid; */
    border-radius: 8px;
    font-size: x-small;
    text-transform: inherit;
    margin: 3px;
   
  }
  /* .ai__display{
    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 5%;
    margin-left: 5%;
  }
 */

  

  /* .resultsPage__footer{
    height: 35vh;
    padding: 3%;
    position: fixed;
  } */