.askfollowup{
    /* position: fixed;
    bottom: 0;
    z-index: 100;
    padding: 3px;
    backdrop-filter: blur(2px); */
    /* width: 100vw; */
    /* margin-bottom: 5%;
    margin-left: 25%; */
}


.askfollowup__input{
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    height: 30px;
    padding: 10px;
    border-radius: 999px;
    width: 90vw;
    margin: 0 auto;
}

.auto__gen{
    margin: 10px !important;
}
.auto__gen__button{
    /* background: rgb(239, 234, 233)  !important;
    color: rgb(19,52,59) !important; */
    background: whitesmoke !important;
    /* border: 0.1px solid gray; */
    color: #1d1d1f !important;
    border: none !important;
    margin: 10px !important;
    display: flex !important;
    border-radius: 15px !important;
    text-transform: unset !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 0.73rem !important;
}

@media only screen and (min-width: 800px) {
    .refine_button_row{
        padding: 0.3% !important;
    }
    .askfollowup__input{
        display: flex;
        align-items: center;
        border: 1px solid lightgray;
        height: 30px;
        padding: 10px;
        border-radius: 999px;
        /* width: 90vw; */
         width: 78vw; 
        margin: 0 auto;
    }
}



.askfollowup__input > input {
    flex: 1;
    padding: 10px 20px;
    font-size: medium;
    border: none;
    /* background-color: ghostwhite; */
}

.askfollowup__input > input:focus {
    outline-width: 0;
}

.askfollowup__buttons__hide{
    /* display: none;; */
}



.askfollowup__buttons{
    /* padding-left: 5%;
    padding-top: 3%; */
    color: black;
}

.refine_button{
    color: black !important;
    opacity: 0.9;
    font-size: 12px !important;
    text-transform: unset !important;
    padding-right: 16px !important;
    padding-left: 11px !important;
    padding-top: 12px !important;
}

/* .refine_button_group{
    padding: 1%;
    padding-left: 20px;
} */

.svg-container svg {
    height: 50px !important
}

    .refine_button{
        color: black !important;
        opacity: 0.9;
        /* font-size: 0.7em !important;
        text-transform: unset !important;
        padding-right: 0% !important; */
    }


/* .refine_button_row{
    padding: 2px;
    padding-left: 12px;
    padding-top: 5px;
} */

.askfollowup__buttons > button{
    /* background-color: rgba(0,0,0,0.05); */
    /* color: #0f0f0f !important; */
    /* border: 1px solid; */
    /* border-radius: 10px;
    font-size: 0.8em;
    text-transform: inherit;
    padding: 2.5%;
    min-width: 85px;
    letter-spacing: 0.01em; */
}
/* .askfollowup__buttons > button:hover{
    background-color: white;
    color: black;
    border: 1px solid;
    border-radius: 10px;
    font-size: x-small;
    text-transform: inherit;
} */